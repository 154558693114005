var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 h-100 d-flex flex-column"},[_c('h4',[_vm._v("Prochaine vidéoconférence")]),(_vm.displayVisios)?_c('div',{staticClass:"mt-4 d-flex justify-content-around align-items-center"},[_c('p',{staticClass:"m-0 font-weight-bold"},[_vm._v(_vm._s(_vm.displayVisios.name))]),_c('div',{staticClass:"d-flex align-items-center ml-5"},[_c('b-icon',{staticClass:"mr-2",staticStyle:{"width":"12px"},attrs:{"icon":"calendar-event-fill","variant":"primary"}}),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.formatDate(_vm.displayVisios.start)))])],1),_c('p',{staticClass:"m-0 ml-5"},[_vm._v("Durée : "+_vm._s(_vm.displayVisios.duration)+" minutes")]),((_vm.tuteur || _vm.admin) && _vm.showButtonDate(_vm.displayVisios.start))?_c('div',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Modifier'),expression:"'Modifier'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-icon btn-circle btn-light btn-sm mr-3",on:{"click":function($event){return _vm.editVisios(_vm.displayVisios)}}},[_vm._m(0)]),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Inscrire des stagiaires'),expression:"'Inscrire des stagiaires'",modifiers:{"hover":true,"top":true}}],staticClass:"btn btn-icon btn-circle btn-light btn-sm",on:{"click":function($event){return _vm.$bvModal.show('modal-inscription-' + _vm.displayVisios.id)}}},[_vm._m(1)]),_c('RegisterIntern',{attrs:{"visio":_vm.displayVisios}})],1):_vm._e(),(_vm.showButtonDate(_vm.displayVisios.start) && !_vm.superviseur)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                !_vm.tuteur && !_vm.admin && _vm.buttonDisabled
                    ? 'La vidéoconférence n\'est pas encore accessible, merci d\'actualiser cette page à ' +
                      _vm.formatTime(_vm.displayVisios.start) +
                      '.'
                    : ''
            ),expression:"\n                !tuteur && !admin && buttonDisabled\n                    ? 'La vidéoconférence n\\'est pas encore accessible, merci d\\'actualiser cette page à ' +\n                      formatTime(displayVisios.start) +\n                      '.'\n                    : ''\n            ",modifiers:{"hover":true,"top":true}}]},[(!_vm.tuteur && !_vm.admin)?_c('router-link',{attrs:{"to":!_vm.displayVisios.is_active && !_vm.tuteur
                        ? { name: 'classroom-visio-waiting', params: { visioId: _vm.displayVisios.id } }
                        : { name: 'classroom-signature', params: { visioId: _vm.displayVisios.id } },"replace":""}},[_c('b-button',{staticClass:"btn btn-icon btn-circle d-flex justify-content-center align-items-center",attrs:{"disabled":!_vm.tuteur && !_vm.admin && _vm.buttonDisabled,"pill":"","variant":"primary"}},[_c('b-icon',{staticStyle:{"width":"70%","height":"auto"},attrs:{"icon":"play-fill"}})],1)],1):(!_vm.admin)?_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.buttonDisabled ? 'La vidéoconférence n\'est pas encore accessible, merci de rééssayer le ' + _vm.formatDate(_vm.displayVisios.start) + '.' : 'Rejoindre la vidéoconférence'),expression:"buttonDisabled ? 'La vidéoconférence n\\'est pas encore accessible, merci de rééssayer le ' + formatDate(displayVisios.start) + '.' : 'Rejoindre la vidéoconférence'",modifiers:{"hover":true,"top":true}}],attrs:{"to":{ name: 'classroom-signature', params: { visioId: _vm.displayVisios.id } },"replace":""}},[_c('b-button',{staticClass:"btn btn-icon btn-circle d-flex justify-content-center align-items-center",attrs:{"disabled":_vm.buttonDisabled,"pill":"","variant":"primary"}},[_c('b-icon',{staticStyle:{"width":"70%","height":"auto"},attrs:{"icon":"play-fill"}})],1)],1):_vm._e()],1):_vm._e()]):_c('div',[_vm._v("Aucune vidéoconférence à venir.")]),_c('UpdateVisio',{attrs:{"selectedVisio":_vm.selectedVisio},on:{"reloadVisios":function($event){return _vm.reloadVisios()}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('i',{staticClass:"fas fa-pen"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary"},[_c('i',{staticClass:"fas fa-user-plus"})])
}]

export { render, staticRenderFns }